import { initSliders } from '../lib/swipers';

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    initSliders();

    if (!('localStorage' in window)) {
      return;
    }
    const ls = window.localStorage;
    const PREFERRED_BRANCH_KEY = 'preferredBranch';
    const $tabContainer = $('#nav-tab');

    if (ls.getItem(PREFERRED_BRANCH_KEY)) {
      $tabContainer
        .find(`[data-branch-id="${ls.getItem(PREFERRED_BRANCH_KEY)}"]`)
        .tab('show');
    }

    $tabContainer.find('a[data-branch-id]').on('click', function() {
      ls.setItem(PREFERRED_BRANCH_KEY, $(this).data('branch-id'));
    });
  },
};
